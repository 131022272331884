<template>
    <div class="">
       <Alert :message='outletsError' type="alert-danger" v-if="outletsError" />

        <div class="row">
              <div class="col-12 my-3">
                  <h2 class="my-3  text-secondary" style="font-size:1.2em; display:inline-block">Outlets</h2>
              </div>
            </div>
            

              <div class="card shadow-sm mb-5">

                 <div class="card-header">
                  <h3 class="my-2 text-secondary" style="font-size:1em;">Outlets</h3>
              </div>

                  <div class="card-body">
                  <div class="table-responsive">
                      <table
                      class="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                      >
                      <thead>
                          <tr>
                            <th>ID</th>
                            <th>Entity Name</th>
                            <th>Market</th>
                            <th>AFCFTA Number</th>
                            <th>Email</th>
                            <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="outlet in outlets" :key="outlet.id">
                            <td>{{outlet.id}}</td>
                            <td>{{outlet.name}}</td>
                            <td>{{outlet.market_name}}</td>
                            <td>{{outlet.afcfta_number}}</td>
                            <td>{{outlet.email}}</td>
                            <td>
                                <button class="btn btn-danger">
                                  issue mandate
                                </button>
                            </td>
                          </tr>
                      </tbody>
                      <tfoot>
                            <tr>
                              <th>ID</th>
                            <th>Entity Name</th>
                            <th>Market</th>
                            <th>AFCFTA Number</th>
                            <th>Email</th>
                            <th>Actions</th>
                          </tr>
                      </tfoot>
                      </table>
                  </div>
                  </div>
              </div>

       <!-- delete modal  content -->
      <div class="modal fade" id="deleteMarket" tabindex="-1" aria-labelledby="Delete Market" aria-hidden="true">
        <div class="modal-dialog">  
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Issue Mandate</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center" v-if="success">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{success}}</strong>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" v-if="error">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-danger" role="alert">
                        <strong>{{error}}</strong>
                    </div>
                  </div>
                </div>
                <button 
                  :disabled='success' style="float:right" class="btn btn-danger" 
                  @click="issueMandate">issue</button>
            </div>
          </div>
        </div>
      </div>
      <!-- end of delete modal content -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/Alert.vue'
const $ = require('jquery')
export default {
    name:'outlets',
    components: {
      Alert
    },
    data() {
      return {
        success:null,
        addError:null,
        outletsError:null,
        error:null,
        loading:false,
      }
    },
    methods: {
      issueMandate() {
        this.loading = true
        this.success = null
        this.error = null
        this.$store.dispatch('giveMandateToOrganisation')
          .then(repsonse => {
            if(resposne.status === 200) {
              this.loading = false
              this.success = 'Mandate Issued.'
            }
          }).catch(err => {
            console.log(err)
            this.loading = false
            this.error = 'Network Error.'
          })
      },
      fetchOutlets() {
          this.$store.dispatch('fetchOutlets')
          .then( response => setTimeout(function(){$('#dataTable').DataTable();}, 5))
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.outletsError = 'Network Error.'
              }
            })
      }
    },
    computed: { ...mapGetters(["outlets"]) },
    created() {
          this.fetchOutlets() 
        },
}
</script>
<style scoped>
  button, table tr td{
    font-size: .9em;
  }
</style>