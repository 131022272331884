<template>
    <div class="">
       <Alert :message='organisationError' type="alert-danger" v-if="organisationError" />
        <div class="row">
              <div class="col-12 my-3">
                  <h2 class="my-3  text-secondary" style="font-size:1.2em; display:inline-block">Organisation</h2>
              </div>
            </div>
              <div class="card shadow-sm mb-5">

                <div class="card-header">
                  <h2 class="my-2 text-secondary" style="font-size:1em;">Organisation</h2>
              </div>

                  <div class="card-body">
                  <div class="table-responsive">
                      <table
                      class="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                      >
                      <thead>
                          <tr>
                            <td>ID</td>
                            <th>Entity Name</th>
                            <th>Market</th>
                            <th>Type</th>
                            <th>AFCFTA Number</th>
                            <th>Email</th>
                            <th>Actions</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="organisation in organisations" :key="organisation.id">
                            <td>{{organisation.id}}</td>
                            <td>{{organisation.name}}</td>
                            <td>{{organisation.market_name}}</td>
                            <td>{{organisation.type}}</td>
                            <td>{{organisation.afcfta_number}}</td>
                            <td>{{organisation.email}}</td>
                            <td>
                                <button @click="getId(organisation.id)" class="btn btn-danger" data-toggle='modal' data-target="#addMandate">
                                  issue mandate
                                </button>
                            </td>

                          </tr>
                      </tbody>
                      <tfoot>
                            <tr>
                              <td>ID</td>
                            <th>Name</th>
                            <th>Market</th>
                            <th>Type</th>
                            <th>AFCFTA Number</th>
                            <th>Email</th>
                            <th>Actions</th>
                          </tr>
                      </tfoot>
                      </table>
                  </div>
                  </div>
              </div>

         <!-- start issue mandate modal content -->
      <div class="modal fade" id="addMandate" tabindex="-1" aria-labelledby="add_mandate" aria-hidden="true">
        <div class="modal-dialog">  
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Issue Mandate</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center" v-if="success">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{success}}</strong>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" v-if="error">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-danger" role="alert">
                        <strong>{{error}}</strong>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <form @submit.prevent="issueMandate">
                        <div class="form-group">
                          <label for="#mandates">Select mandate type</label>
                            <select class="form-control" name="mandates" id="mandates" v-model="mandate" required>
                              <option value="vendor">Vendor</option>
                              <option value="inspector">Inspector</option>
                              <option value="auditor">Auditor</option>
                              <option value="certifier">Certifier</option>
                              <option value="logistics Operator">Logistics Operator</option>
                              <option value="transporter">Transporter</option>
                            </select>
                         </div>
                         <button 
                          type="submit"
                          style="float:right" class="btn btn-danger">
                          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                           issue mandate
                        </button>
                    </form>
                  </div>
                </div>
               
            </div>
          </div>
        </div>
      </div>
      <!-- end of issue mandate modal content -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/Alert.vue'
const $ = require('jquery')
export default {
    name:'organisation',
    components: {
      Alert
    },
    data() {
      return {
        success:null,
        addError:null,
        organisationError:null,
        error:null,
        loading:false,
        mandate:null
      }
    },
    methods: {
      getId(id) {
        this.id = id
        this.error = null
        this.success = null
      },
      issueMandate() {
        this.loading = true
        this.success = null
        this.error = null

        const mandate ={
              "organisation_id": this.id,
              "mandate": this.mandate
          }
          console.log(mandate)
        this.$store.dispatch('giveMandateToOrganisation',mandate )
          .then(response => {
            if(response.status === 200) {
              this.loading = false
              this.success = 'Mandate Issued.'
            }
          }).catch(err => {
            console.log(err)
            this.loading = false
            this.error = 'Network Error.'
          })
      },
      fetchOrganisations() {
          this.$store.dispatch('fetchOrganisations')
            .then(response => setTimeout(function(){$('#dataTable').DataTable();}, 5) )
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.organisationError = 'Network Error.'
              }
            })
      }
    },
    computed: { ...mapGetters(["organisations"]) },
    created() {
          this.fetchOrganisations() 
        },
}
</script>
<style scoped>
  button, table tr td{
    font-size: .9em;
  }
</style>