<template>
    <div class="">
       <Alert :message='agencyError' type="alert-danger" v-if="agencyError" />
  
       <div class="row">
        <div class="col-12 my-3">
            <h2 class="my-3  text-secondary" style="font-size:1.2em; display:inline-block">Agencies</h2>
        </div>
      </div>

          <div class="card shadow-sm mb-5">

            <div class="card-header">
                  <h3 class="my-2 text-secondary" style="font-size:1em;">Agencies</h3>
              </div>

              <div class="card-body">
              <div class="table-responsive">
                  <table
                  class="table table-bordered"
                  id="dataTable"
                  width="100%"
                  cellspacing="0"
                  >
                  <thead>
                      <tr>
                        <th>ID</th>
                        <th>Agency Name</th>
                        <th>Market</th>
                        <th>AFCFTA Number</th>
                        <th>Email</th>
                        <th>Actions</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="agency in agencies" :key="agency.id">
                        <td>{{agency.id}}</td>
                        <td>{{agency.name}}</td>
                        <td>{{agency.market_name}}</td>
                        <td>{{agency.afcfta_number}}</td>
                        <td>{{agency.email}}</td>
                        <td>
                            <button class="btn btn-danger"
                              @click="getId(agency.id)"
                              data-target='#addMandate' data-toggle="modal">
                              issue mandate
                            </button>
                        </td>
                      </tr>
                  </tbody>
                  <tfoot>
                        <tr>
                          <th>ID</th>
                        <th>Entity Name</th>
                        <th>Market</th>
                        <th>AFCFTA Number</th>
                        <th>Email</th>
                        <th>Actions</th>
                      </tr>
                  </tfoot>
                  </table>
              </div>
              </div>
          </div>

       <!-- start issue mandate modal content -->
      <div class="modal fade" id="addMandate" tabindex="-1" aria-labelledby="add_mandate" aria-hidden="true">
        <div class="modal-dialog">  
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Issue Mandate</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center" v-if="success">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{success}}</strong>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" v-if="error">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-danger" role="alert">
                        <strong>{{error}}</strong>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <form @submit.prevent="issueMandate">
                        <div class="form-group">
                          <label for="#mandates">Select mandate type</label>
                            <select class="form-control" name="mandates" id="mandates" v-model="mandate" required>
                              <option value="customs">Customs</option>
                              <option value="plant and animal health">Plant and Animal Health</option>
                              <option value="security">Security</option>
                              <option value="human health">Human Health</option>
                              <option value="other standards">Other Standards</option>
                              <option value="tax">Tax</option>
                              <option value="labour">Labout</option>
                              <option value="immigration">Immigration</option>
                              <option value="fiscal and monetary">Fiscal and Monetary</option>
                            </select>
                        </div>
                         <button 
                          type="submit"
                          style="float:right" class="btn btn-danger">
                          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                           issue mandate
                        </button>
                    </form>
                  </div>
                </div>
               
            </div>
          </div>
        </div>
      </div>
      <!-- end of issue mandate modal content -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/Alert.vue'
const $ = require('jquery')
export default {
    name:'organisation',
    components: {
      Alert
    },
    data() {
      return {
        id:null,
        success:null,
        addError:null,
        agencyError:null,
        error:null,
        loading:false,
        mandate:null
      }
    },
    methods: {
      getId(id) {
        this.id = id
        this.error = null
        this.success = null
      },
      issueMandate() {
        this.loading = true
        this.success = null
        this.error = null

        const mandate ={
              "agency_id": this.id,
              "mandate": this.mandate
          }
        
        this.$store.dispatch('giveMandateToAgency', mandate)
          .then(response => {
            console.log(response)
            if(response.status === 200) {
              this.loading = false
              this.success = 'Mandate Issued.'
            }
          }).catch(err => {
            console.log(err)
            this.loading = false
            this.error = 'Network Error.'
          })
      },
      fetchAgencies() {
          this.$store.dispatch('fetchAgencies')
          .then(response => setTimeout(function(){$('#dataTable').DataTable();}, 5))
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.agencyError = 'Network Error.'
              }
            })
      }
    },
    computed: { ...mapGetters(["agencies"]) },
    created() {
          this.fetchAgencies() 
        },
}
</script>
<style scoped>
  button, table tr td{
    font-size: .9em;
  }
</style>