<template>
    <div class="contaner">
       <Alert :message='suspendSuccess' type="alert-success" v-if="suspendSuccess" />
       <Alert :message='suspendError' type="alert-danger" v-if="suspendError" />

          <div class="row">
        <div class="col-12 my-3">
            <h2 class="my-3  text-secondary" style="font-size:1.2em; display:inline-block">Users</h2>
        </div>
      </div>

            <div class="card shadow-sm mb-5">
              <div class="card-header">
                  <h2 class="my-2 text-secondary" style="font-size:1em;">Users</h2>
              </div>
                <div class="card-body">
                <div class="table-responsive">
                    <table
                    class="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                    >
                    <thead>
                        <tr>
                        <th>ID</th>
                        <th>User Name</th>
                        <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, key) in users" :key="key" >
                          <td>{{user.id}}</td>
                        <td>{{user.first_name}} {{user.last_name}} {{user.other_names}} </td>
                        <td class="actions">
                            <button  
                              href="#" 
                              class="btn btn-info mr-1" @click="getDetails({...user})"
                              data-toggle="modal" data-target="#userDetail">   
                                <i class="fas fa-eye"></i> view
                            </button>
                            <!-- <button 
                              @click="getDetails(entity.id,entity.name,entity.market_name,entity.type, entity.email,entity.phone, key)" 
                              class="btn btn-success mr-1"
                                data-toggle="modal" data-target="#updateMarket">
                                <i class="far fa-edit">   </i> edit
                            </button> -->
                            <button v-if="!user.suspended" @click="suspendEntity(user.id,key)" 
                              class="btn btn-danger mr-1"
                                data-toggle="modal" data-target="#deleteMarket">
                                <i class="fas fa-lock"></i>suspend
                            </button>
                            <button 
                              v-if="user.suspended"  
                              @click="unsuspendEntity(user.id, key)" 
                                class="btn btn-primary mr-1"
                                  data-toggle="modal" data-target="#deleteMarket">
                                <i class="fas fa-lock-open"></i> unsuspend
                            </button>
                            <button v-if="!user.verified_afcfta_number" data-toggle="modal" data-target="#verifyAfcftaNumber" class="btn btn-success mr-1" 
                                @click="userID(user.id,key)">
                                Verify AfCFTA Number
                            </button>
                        </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                          <th>ID</th>
                        <th>User Name</th>
                        <th>Actions</th>
                        </tr>
                    </tfoot>
                    </table>
                </div>
                </div>
            </div>
      <!--add entity modal content -->
      <!-- VERIFY AFCFTA NUMBER -->
      <div class="modal fade" id="verifyAfcftaNumber" tabindex="-1" aria-labelledby="verify afcfta number" aria-hidden="true">
        <div class="modal-dialog">  
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" 
                  style="float:left; color:red; font-weight:bold;font-size:1.3em" 
                  id="exampleModalLabel">
                  Verify Entity AfCFTA Number
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="row justify-content-center" v-if="success">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-success" role="alert">
                        <strong>{{success}}</strong>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" v-if="error">
                  <div class="col-lg-11 col-md-8 col-sm-11">
                    <div  class="text-center alert alert-danger" role="alert">
                        <strong>{{error}}</strong>
                    </div>
                  </div>
                </div>
                <button :disabled="success" style="float:right" 
                class="btn btn-danger" @click="verifyIndividualAfcftaNumber">Verify</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF VERIFY AFCFTA NUMBER -->
       <!-- START OF USER DETAILS -->
      <div class="modal fade" id="userDetail" tabindex="-1" aria-labelledby="Delete Market" aria-hidden="true">
        <div class="modal-dialog modal-lg">  
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-dark" style="color:red; font-weight:bold;font-size:1.3em" id="exampleModalLabel "> Entity Detail</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="card border-left-danger m-4">
                  <div class="card-body">
                      <p><span>Name</span>:<strong>{{user.first_name}} {{user.last_name}} {{user.other_names}}</strong></p>
                      <p><span>Username</span>:<strong>{{user.username}}</strong></p>
                      <p><span>Verified AfCFTA Number</span>:<strong>{{user.verified_afcfta_number? 'Yes' : 'No'}}</strong></p>
                      <p><span>AFCFTA Number</span>:<strong>{{user.afcfta_number}}</strong></p>
                      <p><span>Email</span>:<strong>{{user.email}}</strong></p>
                      <p><span>Phone Number</span>:<strong>{{user.phone}}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF ENTITY DETAILS -->
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import Alert from '@/components/Alert.vue'
const $ = require('jquery')

export default {
    name:'users',
    components: {
        Alert:Alert
      },
    data() {
      return {
        key:null,
        id:null,
        user:{},
        success:null,
        error:null,
        loading:false,
        suspendSuccess:null,
        suspendError:null
       
      } 
    },
    methods: {
        userID(id, key) {
            this.error = null
            this.success = null

            this.key = key
            this.id = id
        },

        verifyIndividualAfcftaNumber(id,key) {
          this.error = null
          this.success = null

          const data = {
             id:this.id,
             key:this.key,
          }
          this.$store.dispatch('verifyIndividualAfcftaNumber',data)
            .then(response => {
              console.log(response)
               this.success = 'User AfCFTA Number Verified'
            }).catch(err => {
              console.log(err)
              if(err?.response?.data === undefined) {
                this.error = 'Network Error.'
              }
            })
      },

      getDetails(user) {
        this.user = user
        console.log(user)
      },
      
      suspendEntity(id,key) {
        this.error = null
        this.success = null
        const data = {
            id:id,
            key:key,
        }
        this.$store.dispatch('suspendEntity',data)
            .then(response => {
            console.log(response)
            this.suspendSuccess = 'Entity Suspended.'
            }).catch(err => {
            console.log('error', err)
            if(err?.response?.data === undefined) {
                this.suspendError = 'Network Error.'
            }
            })
        },

        unsuspendEntity(id,key) {
          this.suspendSuccess = null
          this.suspendError = null
          const data = {
             id:id,
             key:key,
          }
          this.$store.dispatch('unsuspendEntity',data)
            .then(response => {
              console.log(response)
               this.suspendSuccess = 'Entity suspended.'
            }).catch(err => {
              if(err?.response?.data === undefined) {
                this.suspendError = 'Network Error.'
              }
            })
        },
        getUsers() {
           this.$store.dispatch('getUsers').then(response => setTimeout(function(){$('#dataTable').DataTable();}, 100))
            .catch(err => {
              if(err?.response?.data === undefined) {
                this.error = 'Network Error.'
              }
            })
        },

    },
    computed: { 
      ...mapGetters(["users",]),
      details() {
        return this.entityDetail()
      }
    },
    created() {
        this.getUsers()
         
    },
}
</script>
<style>
  .addbutton{
    width:100%;
    font-size: 1em;
  }
  .btn, table tr td{
    font-size: .9em;
  }
</style>